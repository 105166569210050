<template>
  <v-dialog :value="value" @input="close" max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t("studies.acv.edit.title") }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-12">
            <!-- ACV Scope -->
            <v-col cols="12">
              <v-select
                :label="$t('studies.acv.acvScope')"
                v-model="form.acvScope"
                outlined
                :items="$t('constants.acvScopes.list')"
              />
            </v-col>

            <!-- Grades -->
            <v-col cols="12">
              <ProductACVGrades
                :grades.sync="form.grades"
                :is-editable="true"
              />
            </v-col>

            <!-- Numbers -->
            <v-col cols="12">
              <ProductACVNumbers :impact.sync="form" :is-editable="true" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";

export default {
  name: "ProductACVDialog",

  mixins: [dialogMixin, formRules],

  props: {
    study: { type: Object, default: () => null },
  },

  components: {
    ProductACVNumbers: () =>
      import("@/components/Studies/ACV/ProductACVNumbers"),
    ProductACVGrades: () => import("@/components/Studies/ACV/ProductACVGrades"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },

      defaultForm: {
        grades: {
          traceability: 0,
          compliance: 0,
          recyclability: 0,
          environment: null,
        },
        acvScope: "CRADLE_TO_CRADLE",
        loss: 0,
        avgKM: 0,
        resourceUseMineralsMetals: 0,
        ressourceUseFossils: 0,
        acidification: 0,
        ecotoxicityFreshWater: 0,
        humanToxicityCancer: 0,
        humanToxicityNonCancer: 0,
        eutrophicationFreshWater: 0,
        eutrophicationMarine: 0,
        eutrophicationTerrestrial: 0,
        climateChange: 0,
        climateChangeBiogenic: 0,
        climateChangeFossil: 0,
        climateChangeLandUse: 0,
        ionisingRadiation: 0,
        landuse: 0,
        ozoneDepletion: 0,
        particulateMatter: 0,
        photochemicalOzoneFormation: 0,
        waterUse: 0,
      },
    };
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        // console.log(this.form);
        if (this.item) this.submitEdit();
      }
    },

    submitEdit() {
      this.isLoadingBtn.btnSave = true;
      this.$http
        .put(`/studies/${this.study.id}/acv`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("studies.acv.edit.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },
  },

  computed: {},
};
</script>

<style scoped></style>
